<template>
    <div class="page d-flex align-items-start align-items-sm-center justify-content-center">
        <div class="container custom-size">
            <div class="row text-center justify-content-center">
                <horizontal-link class="fs-2"></horizontal-link>
                <h4>Gestão de dashboards</h4>
                <form @submit.prevent="submit" class="row gy-3">
                    <input type="email" id="username" class="form-control" placeholder="Usuário" autofocus>
                    <input type="password" id="password" class="form-control" placeholder="Senha">
                    <div>
                        <button type="submit" class="col-12 btn btn-primary">Acessar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import HorizontalLink from '../components/HorizontalLink.vue'
const config = require('../config')
export default {
    name: 'Admin',
    components: {HorizontalLink},
    methods: {
        submit(event) {
            const body = JSON.stringify({
                username: event.target.username.value,
                password: event.target.password.value
            })
            fetch(config.createLink('/admin/login'), {
                method: 'POST', 
                body,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(r => {
                    switch (r.status) {
                        case 200: 
                            return r.json()
                        case 400:
                            Swal.fire('Erro', 'Requisição inválida', 'error')
                            break
                        case 401:
                            Swal.fire('Erro', 'Credencial inválida', 'error')
                            break
                    }
                    window.localStorage.setItem('admin-token', '')
                })
                .then(j => {
                    if (!j)
                        return
                    window.localStorage.setItem('admin-token', j.token)
                    this.$router.push('/admin/root')
                })
                .catch(e => {
                    Swal.fire('Erro remoto', 'Tente novamente mais tarde', 'error')
                    console.error(e)
                })
        }
    }
}
</script>

<style scoped>
.page{height: 100vh}
form{max-width:400px}
@media only screen and (min-width: 576px) {
    .custom-size{max-width: 375px}
}
</style>