<template>
    <a v-if="parseInt(xDimensaoTela) <= 414" :href="fullLink" target="_blank" v-bind:style="{
          fontSize: 10 + 'px',
        }">
        <img 
            src="../assets/mobile_logo.png" 
            alt="Mobile logo"> 
        <span class="link" :class="{'link-name': !white, 'link-name-white': white}">{{linkName}}</span>
        <span class="link link-domain" >{{linkDomain}}</span>
    </a>

    <a v-else :href="fullLink" target="_blank" v-bind:style="{
          fontSize: 18 + 'px',
        }">
        <img 
            src="../assets/mobile_logo.png" 
            alt="Mobile logo"> 
        <span class="link" :class="{'link-name': !white, 'link-name-white': white}">{{linkName}}</span>
        <span class="link link-domain" >{{linkDomain}}</span>
    </a>
    
</template>

<script>
export default {
    name:'HorizontalLink',
    props: {
        link: {
            type: String,
            default: 'www.grv.com.br'
        },
        white: {
            type: Boolean,
            default: false
        },
        xDimensaoTela:{
            type:Number,
        }
    },
    computed: {
        fullLink() {
            return `http://${this.link}`
        },
        linkName() {
            return this.link.substr(0, this.link.indexOf('.com'))
        },
        linkDomain() {
            return this.link.substr(this.link.indexOf('.com'))
        }
    }
}
</script>

<style scoped>
a{text-decoration: none}
a:hover{text-decoration: underline}
img {height: 1.5em}
.link{font-weight: bold}
.link-name{color: black}
.link-name-white{color: white}
.link-domain{color: black}
</style>