const devConfig = {
      baseUrl: 'https://apontahora.tv/backend',
      baseWsUrl: 'wss://cpsprod.grv.com.br:9092/'
}

const stagingConfig = {
    baseUrl: 'https://teste.apontahora.tv/backend',
    baseWsUrl: 'wss://cpsprod.grv.com.br:9092/'
}

const productionConfig = {
    baseUrl: 'https://apontahora.tv/backend',
    baseWsUrl: 'wss://cpsprod.grv.com.br:9092/'
}

function buildConfig(targetConfig) {
    targetConfig.createLink = function (suffix) {
        return this.baseUrl+suffix
    }
    return targetConfig
}

if (process.env.VUE_APP_STAGING === 'staging')
    module.exports = buildConfig(stagingConfig)
else if (process.env.NODE_ENV === 'production') 
    module.exports = buildConfig(productionConfig)
else 
    module.exports = buildConfig(devConfig)
   